(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

$.fn.scrollTo = function (target, options, callback) {
  if (typeof options == 'function' && arguments.length == 2) {
    callback = options;options = target;
  }
  var settings = $.extend({
    scrollTarget: target,
    offsetTop: 0,
    duration: 500,
    easing: 'swing'
  }, options);
  return this.each(function () {
    var scrollPane = $(this);
    var scrollTarget = typeof settings.scrollTarget == "number" ? settings.scrollTarget : $(settings.scrollTarget);
    var scrollY = typeof scrollTarget == "number" ? scrollTarget : scrollTarget.offset().top + scrollPane.scrollTop() - parseInt(settings.offsetTop);
    scrollPane.animate({ scrollTop: scrollY }, parseInt(settings.duration), settings.easing, function () {
      if (typeof callback == 'function') {
        callback.call(this);
      }
    });
  });
};

$(function () {
  // Modal toggler
  function toggleModal(ancor) {
    var $th = $(ancor),
        active = $th.hasClass('is-active');

    $('.modal').removeClass('is-active');
    $('body').removeClass('is-not-scrollable');

    if (!active) {
      $th.addClass('is-active');
      $('body').addClass('is-not-scrollable');
    }
  }

  $('.js-modal-toggle').each(function () {
    var $th = $(this);

    $th.on('touchstart click', function (e) {
      toggleModal($th.attr('href'));
    });
  });

  $('.js-deactivate').each(function () {
    var $th = $(this);

    $th.on('touchstart click', function (e) {
      $($th.attr('href')).removeClass('is-active');
    });
  });

  $('.js-smooth-scroll').each(function () {
    var $th = $(this);

    $th.on('touchstart click', function (e) {
      $('body').scrollTo($th.attr('href'));
    });
  });

  $('.js-sender').each(function () {
    var $th = $(this);

    $th.on('submit', function (e) {
      e.preventDefault();

      $($th.data('backdrop')).addClass('is-active');

      $.ajax({
        url: $th.attr('action'),
        type: 'post',
        dataType: 'json',
        data: $th.serialize()
      });
    });
  });
});

},{}]},{},[1]);
